import { ComponentProps, Heading, Icon, PillSelector } from '@loveholidays/design-system';
import { useTranslation } from '@loveholidays/phrasebook';
import React from 'react';

import {
  DynamicPackageFiltersInput,
  DynamicPackagePopularFilterGroupElement,
  DynamicPackagePopularFilterGroup,
  InputMaybe,
} from '@AuroraTypes';
import { sendEvent } from '@Core/tracking/sendEvent';
import { TrackingEvent } from '@Core/tracking/types';
import { useSearchResultsStore, useSearchSelectionStore } from '@Stores/StoreContext';
import { Accordion } from '@UX/components/Accordion/Accordion';

export const PopularFilters: React.FC<ComponentProps> = ({ className }) => {
  const { t } = useTranslation();
  const popularFilters: DynamicPackagePopularFilterGroup[] = useSearchResultsStore(
    (state) => state.popularFilters,
  );
  const [selectedFilters, setFilterSelection] = useSearchSelectionStore((state) => [
    state.filters,
    state.setFilterSelection,
  ]);

  const isFilterSelected = (key: keyof DynamicPackageFiltersInput, value: string | number) => {
    const filterSelection = selectedFilters[key] as typeof value;

    return Array.isArray(filterSelection)
      ? filterSelection.includes(value)
      : filterSelection === value;
  };

  const updateSelection = (
    isSelected: boolean,
    filters: DynamicPackagePopularFilterGroupElement[],
  ): Partial<DynamicPackageFiltersInput> => {
    return filters.reduce(
      (acc, { key, value }) => {
        const currentSelection = selectedFilters[key as keyof DynamicPackageFiltersInput];

        // Handle the case when the current selection is an array
        if (Array.isArray(currentSelection)) {
          acc[key as keyof DynamicPackageFiltersInput] = isSelected
            ? currentSelection.filter((item) => item !== value) // Remove value if selected
            : [...currentSelection, value]; // Add value if not selected

          return acc;
        }

        acc[key as keyof DynamicPackageFiltersInput] = isSelected ? undefined : value;

        return acc;
      },
      {} as { [K in keyof DynamicPackageFiltersInput]?: InputMaybe<any> },
    );
  };

  const toggleFilterSelection = (
    isSelected: boolean,
    label: string,
    filters: DynamicPackagePopularFilterGroupElement[],
  ) => {
    setFilterSelection(updateSelection(isSelected, filters));

    sendEvent({
      event: TrackingEvent.popularFilterUsed,
      action: isSelected ? 'remove' : 'add',
      label,
    });
  };

  return (
    <Accordion
      data-id="popular-filters-accordion"
      collapsedByDefault={false}
      className={className}
      sx={{
        marginBottom: '2xs',
        backgroundColor: 'backgroundWhite',
        borderWidth: 'outlinedStrokeWeight',
        borderStyle: 'solid',
        borderColor: 'strokeLightneutral',
        borderRadius: 16,
      }}
      accordionHeaderStyle={{
        paddingY: ['3xs', null, 's'],
        paddingX: 's',
      }}
      accordionHeader={
        <Heading
          variant="smallbold"
          as="h3"
        >
          {t('filters.popularFilters')}
          <Icon
            name="Content/Insights"
            size="20"
            sx={{
              marginLeft: '3xs',
              verticalAlign: 'text-top',
            }}
          />
        </Heading>
      }
    >
      <div
        sx={{
          paddingX: 's',
          paddingBottom: '3xs',
        }}
      >
        {popularFilters?.map(({ label, filters }) => {
          const isGroupSelected = filters.every(({ key, value }) =>
            isFilterSelected(key as keyof DynamicPackageFiltersInput, value),
          );

          return (
            <PillSelector
              data-id={`popular-filter-${label}`}
              key={label}
              selected={isGroupSelected}
              sx={{
                marginRight: '3xs',
                marginBottom: '3xs',
              }}
              onTrigger={() => {
                toggleFilterSelection(isGroupSelected, label, filters);
              }}
            >
              {label}
            </PillSelector>
          );
        })}
      </div>
    </Accordion>
  );
};
